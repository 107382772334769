import React, { useState, useEffect } from 'react';
import m from 'moment';
import Additional from './additional';
import Calendar from './calendar';
import Countdown from './countdown';
import DiscountOffers from './discountOffers';
import MainButton from './mainButton';
import RedBanner from './redBanner';
import ResortSelect from './resortSelect';
import TopBanner from './topBanner';
import ViewAllOffersBtn from './viewAllOffersBtn';
import SpecialsCarousel from './specialsCarousel';
import WithFlightsElement from './withFlightsElement';
import FlightsElement from './flightsElement';
import ResortHeader from './resortHeader';
import useWindowDimensions from '~/js/modules/hooks/useWindowDimensions';
import globalConfig from '../../../modules/global';
import BottomElement from './bottomElement';

// const defaults = {
//   currentDate: new Date(),
// };

const VerticalQQ = props => {
  const { DEFAULTS } = globalConfig;
  const {
    resortHeader = null,
    topBanner = null,
    countdown = {
      endDate: null,
      theme: 'light',
      specials: [],
      counterAlwaysVisible: false,
      counterTitle: '',
    },
    redBanner = null,
    styledCustomClass = '',
    specials = [],
    additional = null,
    activeSale = {},
    discountOffers = {
      leftText: '',
      percentage: 0,
      rightText: '',
      linkUrl: '',
      linkText: '',
    },
    mainButton = null,
    hasMobileLabel = null,
    buttonAction = null,
    targetBlank = false,
    customCallback = null,
    onInputClick = null,
    viewAllOffersBtn = false,
    bottomElement = null,
    calendar = {
      defaultText: '',
      iconCaret: false,
      iconCalendar: false,

      monthsToDisplay: 0,
      theme: 'light',
      checkinDateName: '',
      checkoutDateName: '',
      minDate: DEFAULTS.minDate,
      maxDate: DEFAULTS.maxDate,
      minimumNights: 1,
      customMinimumNightsError: 3,
      alignment: 'right',
      portalClass: 'default__portal',
      onApplyDates: () => {},
      onCloseCalendar: () => {},
      onClearDates: () => {},
    },
    resortSelect = {
      resortOptions: [],
      labelText: '',
      isResortRequired: false,
      storeResortCode: false,
    },
    saveVacationData,
    vacationData,
    bigCounter,
    glass,
    alignment = 'vertical',
    resetQQ = false,
    withFlights = false,
    collapsibleBanner = false,
    withFlightsId = [''],
    dataLayerEvent = 'search_site',
  } = props;
  const {
    endDate,
    theme: countDownTheme,
    counterAlwaysVisible,
    counterTitle,
  } = countdown;
  const { leftText, percentage, rightText, linkUrl, linkText, icon } =
    discountOffers;
  const { resortOptions, labelText, isResortRequired, storeResortCode } =
    resortSelect;
  const {
    defaultText,
    iconCaret,
    iconCalendar,
    saveInputDate,
    monthsToDisplay,
    theme: calendarTheme,
    checkinDateName,
    checkoutDateName,
    minDate,
    maxDate,
    minimumNights,
    customMinimumNightsError = 3,
    alignment: calendarAlign = 'right',
    portalClass,
    onApplyDates,
    onClearDates,
    onCloseCalendar,
    bookNowTest = null,
    isDatesRequired = false,
  } = calendar;
  const { width } = useWindowDimensions();
  const isDesktop = width >= 768;

  const specialsLen = specials.length;
  const hasSpecials = specialsLen > 0;
  const hasActiveSale = Object.keys(activeSale).length > 0;
  const countdownSlideIndex = hasActiveSale ? 1 : 0;
  const [specialsVisible, setSpecialsVisible] = useState(false);
  const [carouselItemId, setCarouselItemId] = useState(0);
  const [withFlightsVisible, setWithFlightsVisible] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState('');
  const [airportArrivingTo, setAirportArrivingTo] = useState('ocj');

  const [counterVisible, setCounterVisible] = useState(false);
  const [widthToggle, setWidthToggle] = useState(false);
  const [redBannerBackground, setRedBannerBackground] = useState(false);

  const arrivalGatewayResorts = ['SGO', 'BRP', 'SDR'];

  const handleSpecialsRedirect = () => {
    window.location.href = activeSale.URL;
  };

  const resetElement = () => {
    setSpecialsVisible(false);
    setCarouselItemId(0);
  };

  const getValidDates = () => {
    const checkIn = m(vacationData.checkInDate, 'MM-DD-YYYY').day();
    const checkOut = m(vacationData.checkOutDate, 'MM-DD-YYYY').day();

    const isValidDates =
      (checkIn === 3 || checkIn === 6) && (checkOut === 3 || checkOut === 6);

    return isValidDates;
  };

  const toggleWithFlights = () => {
    const seatType = withFlightsVisible ? '' : 'economy';

    setWithFlightsVisible(!withFlightsVisible);
    saveVacationData({ gateway: '', seatType });

    setAirportArrivingTo(
      arrivalGatewayResorts.indexOf(vacationData.rstCode) !== -1 &&
        getValidDates() &&
        !withFlightsVisible
        ? 'ocj'
        : ''
    );
    saveVacationData({
      arrivalGateway:
        arrivalGatewayResorts.indexOf(vacationData.rstCode) !== -1 &&
        getValidDates() &&
        !withFlightsVisible
          ? 'ocj'
          : '',
    });
  };
  const handleSearchCriteriaChange = e => {
    const { currentTarget } = e;
    const { value: seatType } = currentTarget;

    setSearchCriteria(seatType);
    saveVacationData({ seatType });
  };

  const handleAirportArrivingTo = e => {
    const { currentTarget } = e;
    const { value: arrivalGateway } = currentTarget;

    setAirportArrivingTo(arrivalGateway);
    saveVacationData({ arrivalGateway });
  };

  const selectGateway = ({ value: gateway }) => {
    saveVacationData({ gateway });
  };
  const glassClass = glass ? 'glass' : 'solid';

  useEffect(() => {
    if (resetQQ) {
      resetElement();
      sessionStorage.removeItem('rstCode');
      saveVacationData({
        rstCode: '',
        checkInDate: '',
        checkOutDate: '',
        gateway: '',
        seatType: 'economy',
        arrivalGateway: '',
      });
    }
  }, [resetQQ]);

  useEffect(() => {
    if (isDesktop) {
      setCounterVisible(true);
      setRedBannerBackground(false);
    } else {
      setWidthToggle(true);
    }
  }, [width]);

  useEffect(() => {
    if (!isDesktop) {
      setCounterVisible(false);
      setWidthToggle(false);
      setSpecialsVisible(false);
      setRedBannerBackground(false);
    }
  }, [widthToggle]);

  return (
    <div
      className={`vertical-qq qq-background-${glassClass} ${styledCustomClass}`}
    >
      {hasSpecials && specialsVisible && (
        <button
          className={`qq-close-btn ${
            carouselItemId > countdownSlideIndex ? 'adjust' : ''
          }`}
          onClick={resetElement}
        >
          &times;
        </button>
      )}
      {resortHeader && <ResortHeader {...resortHeader} />}
      {topBanner && <TopBanner>{topBanner}</TopBanner>}
      {endDate && (
        <Countdown
          endDate={endDate}
          theme={countDownTheme}
          bigCounter={bigCounter}
          carouselItemId={carouselItemId}
          counterAlwaysVisible={counterAlwaysVisible}
          counterVisible={counterVisible}
          hasActiveSale={hasActiveSale}
          counterTitle={counterTitle}
        />
      )}
      {redBanner && (
        <RedBanner
          redBanner={redBanner}
          hasSpecials={hasSpecials}
          carouselItemId={carouselItemId}
          specialsVisible={specialsVisible}
          handleSpecialsRedirect={() => handleSpecialsRedirect()}
          redBannerBackground={redBannerBackground}
          collapsibleBanner={collapsibleBanner}
        />
      )}
      {hasSpecials && (
        <SpecialsCarousel
          specials={specials}
          specialsLen={specialsLen}
          visible={specialsVisible}
          setCarouselItemId={setCarouselItemId}
          carouselItemId={carouselItemId}
        />
      )}
      {additional && <Additional>{additional}</Additional>}
      {percentage > 0 && (
        <div className="row center-xs middle-xs qq-element-wrap">
          <div className="xs-12">
            <DiscountOffers
              leftText={leftText}
              percentage={percentage}
              rightText={rightText}
              linkUrl={linkUrl}
              linkText={linkText}
              icon={icon}
            />
          </div>
        </div>
      )}
      {resortOptions.length > 0 && (
        <div className="row center-xs middle-xs qq-element-wrap">
          <div className="xs-12">
            <ResortSelect
              alignment={alignment}
              resortOptions={resortOptions}
              labelText={labelText}
              vacationData={vacationData}
              saveVacationData={saveVacationData}
              onInputClick={onInputClick}
              resetInput={resetQQ}
              setAirportArrivingTo={setAirportArrivingTo}
              isRequired={isResortRequired}
              withFlightsVisible={withFlightsVisible}
              arrivalGatewayResorts={arrivalGatewayResorts}
              storeResortCode={storeResortCode}
            />
          </div>
        </div>
      )}

      <div
        className={`row center-xs middle-xs qq-element-wrap qq-styled-calendar ${
          monthsToDisplay > 0 ? '' : 'hidden-xs-min-up'
        }`}
      >
        <div className={styledCustomClass ? 'xs-7 sm-12' : 'xs-12'}>
          <Calendar
            defaultText={defaultText}
            iconCaret={iconCaret}
            iconCalendar={iconCalendar}
            saveInputDate={saveInputDate}
            theme={calendarTheme}
            checkinDateName={checkinDateName}
            checkoutDateName={checkoutDateName}
            minDate={minDate}
            maxDate={maxDate}
            minimumNights={minimumNights}
            customMinimumNightsError={customMinimumNightsError}
            monthsToDisplay={monthsToDisplay}
            vacationData={vacationData}
            saveVacationData={saveVacationData}
            alignment={calendarAlign}
            onInputClick={onInputClick}
            portalClass={portalClass}
            resetInput={resetQQ}
            onApplyDates={onApplyDates}
            onClearDates={onClearDates}
            onCloseCalendar={onCloseCalendar}
            styledCustomClass={styledCustomClass}
            resortOptions={resortOptions}
            withFlightsVisible={withFlightsVisible}
            isRequired={isDatesRequired}
            setAirportArrivingTo={setAirportArrivingTo}
            arrivalGatewayResorts={arrivalGatewayResorts}
          />
        </div>
        {styledCustomClass && (
          <div className="xs-4 qq-styled-mobile-button">
            <MainButton
              copy={mainButton}
              bottomElement={bottomElement}
              vacationData={vacationData}
              buttonAction={buttonAction}
              targetBlank={targetBlank}
              dataLayerEvent={dataLayerEvent}
              customCallback={customCallback}
              resortOptions={resortOptions}
              withFlightsVisible={withFlightsVisible}
              isResortRequired={isResortRequired}
              isDatesRequired={isDatesRequired}
              bookNowTest={bookNowTest}
            />
          </div>
        )}
      </div>

      {bottomElement && hasMobileLabel && (
        <div className="hidden-sm-min-up qq-mobile-label">
          <BottomElement>{bottomElement}</BottomElement>
        </div>
      )}

      {withFlights && (
        <div className="row center-xs middle-xs with-flights-vertical qq-element-wrap">
          <div className="xs-12">
            <WithFlightsElement
              withFlightsId={withFlightsId}
              value={withFlightsVisible}
              onClick={toggleWithFlights}
              labelCopy="Include Roundtrip Flights in this quote"
            />
          </div>
        </div>
      )}
      {withFlights && withFlightsVisible && (
        <div className="row center-xs middle-xs with-flights-vertical qq-element-wrap">
          <div className="xs-12">
            <div className="row">
              <FlightsElement
                searchCriteria={searchCriteria}
                handleSearchCriteriaChange={handleSearchCriteriaChange}
                selectGateway={selectGateway}
                resetInput={resetQQ}
                flightsElementClassName="xs-12 flights-departing"
                searchCriteriaClassName="xs-12 qq-resort-select"
                airportArrivingToClassName="xs-12 airport-arriving qq-resort-select"
                handleAirportArrivingTo={handleAirportArrivingTo}
                airportArrivingTo={airportArrivingTo}
                setAirportArrivingTo={setAirportArrivingTo}
                vacationData={vacationData}
                arrivalGatewayResorts={arrivalGatewayResorts}
                isRequired
              />
            </div>
          </div>
        </div>
      )}
      {mainButton && (
        <div className="row center-xs middle-xs qq-element-wrap qq-styled-main-button">
          <div className="xs-12">
            <MainButton
              copy={mainButton}
              bottomElement={bottomElement}
              vacationData={vacationData}
              buttonAction={buttonAction}
              targetBlank={targetBlank}
              customCallback={customCallback}
              resortOptions={resortOptions}
              withFlightsVisible={withFlightsVisible}
              isResortRequired={isResortRequired}
              isDatesRequired={isDatesRequired}
              bookNowTest={bookNowTest}
              dataLayerEvent={dataLayerEvent}
            />
          </div>
        </div>
      )}
      {viewAllOffersBtn && <ViewAllOffersBtn />}
    </div>
  );
};

export default VerticalQQ;
