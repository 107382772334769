import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import m from 'moment';
import 'react-dates/initialize';
import { DayPickerRangeController } from 'react-dates';
import Portal from '~/js/components/global/portal/Portal';
import useDisabledScroll from '~/js/modules/hooks/useDisabledScroll';
import useWindowDimensions from '~/js/modules/hooks/useWindowDimensions';
import Wrapper from '~/js/components/global/wrapper/Wrapper';
import {
  obeFormat,
  defaultFormat,
  dateFormatter,
} from '~/js/components/resorts/rooms/utils/dateUtils';
import {
  HORIZONTAL_ORIENTATION,
  VERTICAL_SCROLLABLE,
  isInclusivelyAfterDay,
} from './reactDatesUtilities';
import useScrollIntoView from '~/js/modules/hooks/useScrollIntoView';
import ResolveImg from '~/js/components/global/image/ResolveImg';
import globalConfig from '../../../modules/global';

const { data = {} } = window.sandals_app.page;
const { resorts, resort } = data;

const currentResort = resorts ? resorts[0] : resort;
const resortCode = currentResort?.code;
const currentCountry = currentResort?.country?.name.toLowerCase();

const Calendar = ({
  theme,
  minDate,
  maxDate,
  monthsToDisplay,
  minimumNights,
  customMinimumNightsError = 3,
  checkinDateName,
  checkoutDateName,
  alignment = 'right',
  iconCaret,
  iconCalendar,
  saveInputDate = true,
  vacationData,
  saveVacationData,
  onInputClick,
  portalClass = 'default__portal',
  onApplyDates = () => {},
  onClearDates = () => {},
  resetInput,
  horizontalSpecialAlignment,
  styledCustomClass = false,
  resortOptions,
  defoCheckinDate = 'Check-In',
  defoCheckoutDate = 'Check-Out',
  withFlightsVisible,
  isRequired = false,
  setAirportArrivingTo = null,
  arrivalGatewayResorts = [],
}) => {
  const { DEFAULTS } = globalConfig;

  const defoSingleInputCheckDate = `${defoCheckinDate} & ${defoCheckoutDate}`;
  const resortCodeOption =
    resortOptions.length > 0 ? vacationData.rstCode : resortCode;

  const mMinDate = m(minDate || DEFAULTS.minDate, 'MM-DD-YYYY', true);
  const mMaxDate = m(maxDate || DEFAULTS.maxDate, 'MM-DD-YYYY', true);

  const [checkinDate, setCheckinDate] = useState(defoCheckinDate);
  const [checkoutDate, setCheckoutDate] = useState(defoCheckoutDate);
  const [singleInputCheckDate, setSingleInputCheckDate] = useState(
    defoSingleInputCheckDate
  );
  const [calendarStartDate, setCalendarStartDate] = useState(null);
  const [calendarEndDate, setCalendarEndDate] = useState(null);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [prevVisible, setPrevVisible] = useState(false);
  const [nextVisible, setNextVisible] = useState(true);
  const [focusedInput, setFocusedInput] = useState('startDate');
  const [size, setSize] = useState([0, 0]);
  const [showMaxNightsError, setShowMaxNightsError] = useState(false);
  const [showMinNightsError, setShowMinNightsError] = useState(false);
  const [disabledScroll, setDisabledScroll] = useState(false);

  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const singleInputDateRef = useRef(null);
  const calendarRef = useRef(null);
  const { width } = useWindowDimensions();
  const isMobile = width < 768;
  const isTablet = width < 992;
  const CalendarWrapper = isMobile ? Portal : Wrapper;

  const [sanFormat, setSanFormat] = useState(
    styledCustomClass && isTablet ? 'MMM DD, YYYY' : 'ddd, MMM DD, YYYY'
  );

  const customCalendarRenders = {
    SSV: {
      colorCodeText: 'Best roundtrip flights to Saint Vincent',
      renderFunc: day => {
        const result =
          day.day() === 0 ||
          day.day() === 1 ||
          day.day() === 3 ||
          day.day() === 6;

        return (
          <span className={result ? 'colored' : ''}>{day.format('D')}</span>
        );
      },
      hasColorCode: true,
    },
  };

  const customRender = customCalendarRenders[vacationData.rstCode];

  useDisabledScroll(disabledScroll);
  useScrollIntoView(singleInputDateRef, calendarVisible, true);

  const checkNavsVisibility = date => {
    const maxDateClone = mMaxDate.clone();
    const maxDateMinusMonths = maxDateClone.subtract(2, 'months');

    let prvVisible = true;

    let nxtVisible = true;

    // added the second parameter 'month' to compare only months
    // displaying of arrows for navigation through months dependes only on months
    // more info about momentjs: https://momentjscom.readthedocs.io/en/latest/moment/05-query/03-is-after/

    if (date.isAfter(mMinDate, 'month')) {
      prvVisible = true;
    } else {
      prvVisible = false;
    }

    if (date.isBefore(maxDateMinusMonths, 'month')) {
      nxtVisible = true;
    } else {
      nxtVisible = false;
    }

    setPrevVisible(prvVisible);
    setNextVisible(nxtVisible);
  };

  const hideCalendar = () => {
    setCalendarVisible(false);
    setDisabledScroll(false);
  };

  useEffect(() => {
    // Used to scroll the calendar, when opened, to the selected date in case there is one, on mobile
    if (calendarVisible && calendarStartDate && isMobile) {
      let monthsContainer =
        document.getElementsByClassName('CalendarMonthGrid');

      if (monthsContainer && monthsContainer?.length > 0) {
        monthsContainer = monthsContainer[0];

        // Month difference from the current date to the start date from the selected range on the calendar
        let monthDifference = calendarStartDate
          ? Math.ceil(calendarStartDate.diff(mMinDate, 'months', true))
          : undefined;

        // Grabbing the month where the range was selected to scroll to it when the calendar is opened
        monthDifference =
          monthDifference > monthsContainer?.children.length
            ? monthsContainer?.children.length
            : monthDifference;

        let monthOnFocus = monthsContainer?.children[
          monthDifference - 1
        ]?.getElementsByClassName('CalendarMonth_caption');

        // Scroll to the month element
        if (monthOnFocus && monthOnFocus?.length > 0) {
          monthOnFocus = monthOnFocus[0];
          monthOnFocus.scrollIntoView({
            block: 'start',
            behavior: 'instant',
          });
        }
      }
    }
  }, [calendarVisible]);

  const showCalendar = () => {
    // check visibility of arrows right on displaying calendar without waiting for another events
    if (!calendarVisible) {
      checkNavsVisibility(calendarStartDate ? calendarStartDate : mMinDate);
    }
    setCalendarVisible(true);
  };

  const handleApplyDates = () => {
    hideCalendar();
    onApplyDates({ checkinDate, checkoutDate });
  };

  const handleToggleCalendar = e => {
    const defoRef = { contains: () => false };
    const singleInput = singleInputDateRef.current || defoRef;
    const startInput = startDateRef.current || defoRef;
    const endInput = endDateRef.current || defoRef;
    const calendar = calendarRef.current || defoRef;

    if (e) {
      const inputClicked =
        singleInput.contains(e.target) ||
        startInput.contains(e.target) ||
        endInput.contains(e.target);
      const calendarClicked = calendar.contains(e.target);

      const sessionDatesChecked =
        sessionStorage.getItem('startDate') &&
        sessionStorage.getItem('endDate');

      if (sessionDatesChecked) {
        if (calendar instanceof Element) {
          const closestSelect =
            calendar.closest('.san-form-group') !== null
              ? calendar.closest('.san-form-group')
              : singleInputDateRef.current?.closest('.san-form-group');

          if (closestSelect?.classList.contains('is-invalid')) {
            closestSelect.classList.toggle('is-invalid');
          } else {
            const closestSingleSelect =
              startInput instanceof HTMLElement
                ? startInput.closest('.san-form-group')
                : null;

            if (closestSingleSelect?.classList.contains('is-invalid')) {
              closestSingleSelect.classList.toggle('is-invalid');
            }
          }
        }
      }

      if (inputClicked) {
        showCalendar();

        if (isMobile) {
          setDisabledScroll(true);
        }
      }

      if (!inputClicked && !calendarClicked) {
        hideCalendar();
      }
    }
  };

  const clearDates = () => {
    setCalendarStartDate(null);
    setCalendarEndDate(null);
    setCheckinDate(defoCheckinDate);
    setCheckoutDate(defoCheckoutDate);
    setSingleInputCheckDate(defoSingleInputCheckDate);
    saveVacationData({ checkInDate: '', checkOutDate: '', arrivalGateway: '' });
    sessionStorage.removeItem('startDate');
    sessionStorage.removeItem('endDate');
    onClearDates();
  };

  const clearHoveredDays = () => {
    const hoveredDays = document.querySelectorAll('.CalendarDay__hovered_span');

    hoveredDays.forEach(item =>
      item.classList.remove('CalendarDay__hovered_span')
    );
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    const checkInDate = startDate.format('MM-DD-YYYY');
    const checkOutDate = endDate ? endDate.format('MM-DD-YYYY') : '';
    const sanFormatCheckInDate = startDate.format(sanFormat);

    let nightsDiff = 0;

    // check if max 21 nights stay
    if (endDate) {
      nightsDiff = endDate.diff(startDate, 'days', true);
    }

    if (startDate && endDate === null) {
      clearDates();
      setTimeout(() => {
        clearHoveredDays();
      }, 300);
    }

    setCalendarStartDate(startDate);
    setCheckinDate(sanFormatCheckInDate);

    if (nightsDiff >= customMinimumNightsError && nightsDiff <= 21) {
      const sanFormatCheckOutDate = endDate ? endDate.format(sanFormat) : '';
      const sanFormatSingleInputCheckDate = `${sanFormatCheckInDate} - ${
        sanFormatCheckOutDate ? sanFormatCheckOutDate : ''
      }`;

      setCalendarEndDate(endDate);

      setCheckoutDate(sanFormatCheckOutDate);
      setSingleInputCheckDate(sanFormatSingleInputCheckDate);

      saveVacationData({ checkInDate, checkOutDate });

      if (saveInputDate) {
        // Set dates to sessionStorage
        const savedCheckIn = dateFormatter(startDate, defaultFormat, obeFormat);
        const savedCheckOut = dateFormatter(endDate, defaultFormat, obeFormat);

        if (startDate._isValid && endDate._isValid) {
          sessionStorage.setItem('startDate', savedCheckIn);
          sessionStorage.setItem('endDate', savedCheckOut);
          sessionStorage.setItem('generalRstCode', resortCodeOption);
        }
      }

      const checkIn = m(checkInDate, 'MM-DD-YYYY').day();
      const checkOut = m(checkOutDate, 'MM-DD-YYYY').day();

      const isValidDates =
        (checkIn === 3 || checkIn === 6) && (checkOut === 3 || checkOut === 6);

      if (setAirportArrivingTo) {
        setAirportArrivingTo(
          withFlightsVisible &&
            isValidDates &&
            arrivalGatewayResorts.indexOf(vacationData.rstCode) !== -1
            ? 'ocj'
            : ''
        );
        saveVacationData({
          arrivalGateway:
            withFlightsVisible &&
            isValidDates &&
            arrivalGatewayResorts.indexOf(vacationData.rstCode) !== -1
              ? 'ocj'
              : '',
          checkInDate,
          checkOutDate,
        });
      }

      setShowMaxNightsError(false);
      setShowMinNightsError(false);
    } else if (nightsDiff > 1 && nightsDiff > 21) {
      // display error
      setShowMaxNightsError(true);
      setCalendarEndDate(null);
      setCheckoutDate('');
      clearHoveredDays();
    } else if (nightsDiff > 0 && nightsDiff < customMinimumNightsError) {
      // display error
      setShowMinNightsError(true);
      setCalendarEndDate(null);
      setCheckoutDate('');
      clearHoveredDays();
    }
  };

  const isOutsideRange = day => {
    const isMinDate = !isInclusivelyAfterDay(day, minDate || mMinDate);
    const isMaxDate = isInclusivelyAfterDay(day, maxDate || DEFAULTS.maxDate);

    return isMinDate || isMaxDate;
  };

  const onNavClick = date => {
    checkNavsVisibility(date);
  };

  const onSelectChange = ({ month, onYearSelect }) => {
    return e => {
      const { value } = e.currentTarget;

      onYearSelect(month, Number(value));
      checkNavsVisibility(month.add(Number(value) - month.year(), 'year'));
    };
  };

  const renderMonthHeader = ({ month, onYearSelect }) => {
    const minDateYear = mMinDate.clone().year();
    const maxDateYear = mMaxDate.clone().year();
    const yearOptions = [];
    const currentYear = month.format('YYYY');

    for (let i = minDateYear; i < maxDateYear; ++i) {
      yearOptions.push(i);
    }

    return (
      <div className="qq-calendar-headers">
        <span>{month.format('MMMM')}</span>
        <div className="qq-input-wrap qq-select qq-header-select">
          <div className="select-wrapper with-two-arrows">
            <select
              name="qq-year-select"
              className="qq-input qq-year-select"
              value={currentYear}
              onChange={onSelectChange({ month, onYearSelect })}
            >
              {yearOptions.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  };

  const handleFocusChange = focusInput => {
    setFocusedInput(focusInput || 'startDate');
  };

  const numberOfMonthsToShow =
    DEFAULTS.maxDate.diff(minDate || mMinDate, 'months') + 1;

  const handleCustomClick = e => {
    if (onInputClick && e.currentTarget.name) {
      onInputClick(e.currentTarget.name);
    }
  };

  const registerMouseDown = () => {
    document.addEventListener('mousedown', handleToggleCalendar);
    document.addEventListener('focusin', handleToggleCalendar);
  };

  const unRegisterMouseDown = () => {
    document.removeEventListener('mousedown', handleToggleCalendar);
    document.removeEventListener('focusin', handleToggleCalendar);
  };

  useEffect(() => {
    registerMouseDown();

    return () => {
      unRegisterMouseDown();
    };
  });

  useEffect(() => {
    if (resetInput) {
      hideCalendar();
      onClearDates();
      setCheckinDate(defoCheckinDate);
      setCheckoutDate(defoCheckoutDate);
      setSingleInputCheckDate(defoSingleInputCheckDate);
      setCalendarStartDate(null);
      setCalendarEndDate(null);
    }
  }, [resetInput]);

  useEffect(() => {
    setSanFormat(
      styledCustomClass && isTablet ? 'MMM DD, YYYY' : 'ddd, MMM DD, YYYY'
    );

    const enableSSV =
      sessionStorage.getItem('startDate') !== null
        ? m(sessionStorage.getItem('startDate')).isSameOrAfter(
            DEFAULTS.minDateSSV
          )
        : true;

    if (!enableSSV && resortCodeOption === 'SSV') {
      sessionStorage.setItem('endDate', '');
      sessionStorage.setItem('startDate', '');
    }

    const sessionCheckIn = sessionStorage.getItem('startDate');
    const sessionCheckOut = sessionStorage.getItem('endDate');

    if (sessionCheckIn && sessionCheckOut) {
      const checkInMoment = m(sessionCheckIn);
      const checkOutMoment = m(sessionCheckOut);

      const currentMinDate = minDate || mMinDate;
      const isInvalid = !isInclusivelyAfterDay(checkInMoment, currentMinDate);

      if (isInvalid) {
        sessionStorage.removeItem('startDate');
        sessionStorage.removeItem('endDate');
        sessionStorage.removeItem('generalRstCode');

        setCheckinDate(defoCheckinDate);
        setCheckoutDate(defoCheckoutDate);
        setSingleInputCheckDate(defoSingleInputCheckDate);
      } else {
        const sanFormatCheckInDate = checkInMoment.format(sanFormat);
        const sanFormatCheckOutDate = checkOutMoment.format(sanFormat);
        const sanFormatSingleInputCheckDate = `${sanFormatCheckInDate} - ${
          sanFormatCheckOutDate ? sanFormatCheckOutDate : ''
        }`;

        setCheckinDate(sanFormatCheckInDate);
        setCheckoutDate(sanFormatCheckOutDate);
        setSingleInputCheckDate(sanFormatSingleInputCheckDate);
      }
    }
  }, [width]);

  useEffect(() => {
    if (saveInputDate) {
      const enableSSV =
        sessionStorage.getItem('startDate') !== null
          ? m(sessionStorage.getItem('startDate')).isSameOrAfter(
              DEFAULTS.minDateSSV
            )
          : true;

      if (!enableSSV && resortCodeOption === 'SSV') {
        sessionStorage.setItem('endDate', '');
        sessionStorage.setItem('startDate', '');
      }

      const sessionCheckIn = sessionStorage.getItem('startDate');
      const sessionCheckOut = sessionStorage.getItem('endDate');

      if (sessionCheckIn && sessionCheckOut) {
        const checkInMoment = m(sessionCheckIn);
        const checkOutMoment = m(sessionCheckOut);

        const currentMinDate = minDate || mMinDate;
        const isInvalid = !isInclusivelyAfterDay(checkInMoment, currentMinDate);

        if (isInvalid) {
          sessionStorage.removeItem('startDate');
          sessionStorage.removeItem('endDate');
          sessionStorage.removeItem('generalRstCode');
        } else {
          const sanFormatCheckInDate = checkInMoment.format(sanFormat);
          const sanFormatCheckOutDate = checkOutMoment.format(sanFormat);
          const sanFormatSingleInputCheckDate = `${sanFormatCheckInDate} - ${
            sanFormatCheckOutDate ? sanFormatCheckOutDate : ''
          }`;

          setCalendarStartDate(checkInMoment);
          setCalendarEndDate(checkOutMoment);
          setCheckinDate(sanFormatCheckInDate);
          setCheckoutDate(sanFormatCheckOutDate);
          setSingleInputCheckDate(sanFormatSingleInputCheckDate);

          handleDatesChange({
            startDate: checkInMoment,
            endDate: checkOutMoment,
          });
        }
      }
    }
  }, []);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <div
      className={`row center-xs top-xs qq-calendar ${theme} ${
        isRequired ? 'san-form-group' : ''
      }`}
    >
      {checkinDateName && (
        <>
          <div
            className={`xs-12${
              horizontalSpecialAlignment ? ' md-6' : ''
            } qq-input-wrapper qq-input-${checkinDateName}-wrapper`}
          >
            <button
              name={checkinDateName}
              id={checkinDateName}
              className={`qq-input ${checkinDateName} ${
                checkinDate === defoCheckinDate ? 'qq-input--placeholder' : ''
              } ${isRequired ? 'san-input' : ''}`}
              ref={startDateRef}
              onMouseDown={handleCustomClick}
            >
              {checkinDate}
            </button>
            {iconCaret && (
              <i role="presentation" aria-label="icon" className=" qq-icon" />
            )}
            {iconCalendar && (
              <ResolveImg
                imgData={{
                  src: 'https://cdn.sandals.com/sandals/v13/images/EN/global/elements/calendar.svg',
                  alt: 'calendar',
                  className: 'qq-icon js-resolve',
                }}
              />
            )}

            {isRequired && (
              <div className="xs-12 no-pg">
                <div className="has-error">
                  <span className="san-feedback">This field is required.</span>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {checkoutDateName && (
        <>
          <div
            className={`xs-12${
              horizontalSpecialAlignment ? ' md-6' : ''
            } qq-input-wrapper qq-input-${checkoutDateName}-wrapper`}
          >
            <button
              name={checkoutDateName}
              id={checkoutDateName}
              className={`qq-input ${checkoutDateName} ${
                checkoutDate === defoCheckoutDate ? 'qq-input--placeholder' : ''
              } ${isRequired ? 'san-input' : ''}`}
              ref={endDateRef}
              onMouseDown={handleCustomClick}
            >
              {checkoutDate}
            </button>
            {iconCaret && (
              <i role="presentation" aria-label="icon" className=" qq-icon" />
            )}
            {iconCalendar && (
              <ResolveImg
                imgData={{
                  src: 'https://cdn.sandals.com/sandals/v13/images/EN/global/elements/calendar.svg',
                  alt: 'calendar',
                  className: 'qq-icon js-resolve',
                }}
              />
            )}
            {isRequired && (
              <div className="xs-12 no-pg">
                <div className="has-error">
                  <span className="san-feedback">This field is required.</span>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {!checkinDateName && !checkoutDateName && (
        <>
          <div className={'xs-12 qq-input-wrapper'}>
            <button
              name="checkin-out-date"
              id="checkin-out-date"
              className={`qq-input checkin-out-date ${
                isRequired ? 'san-input' : ''
              }`}
              ref={singleInputDateRef}
              onMouseDown={handleCustomClick}
            >
              {singleInputCheckDate}
            </button>
            {iconCaret && (
              <i role="presentation" aria-label="icon" className=" qq-icon" />
            )}
            {iconCalendar && (
              <ResolveImg
                imgData={{
                  src: 'https://cdn.sandals.com/sandals/v13/images/EN/global/elements/calendar.svg',
                  alt: 'calendar',
                  className: 'qq-icon js-resolve',
                }}
              />
            )}
          </div>
          {isRequired && (
            <div className="xs-12">
              <div className="has-error">
                <span className="san-feedback">This field is required.</span>
              </div>
            </div>
          )}
        </>
      )}
      {calendarVisible && (
        <CalendarWrapper id={isMobile ? 'qq-calendar' : undefined}>
          <div
            ref={calendarRef}
            id={`qq-calendar__${vacationData?.rstCode}`}
            className={`qq-calendar-wrapper align-to-${alignment} fix-viewport-size ${portalClass} ${
              customRender ? 'custom-render' : ''
            }`}
            style={
              isMobile ? { height: `${size[1]}px` } : { height: '41.8rem' }
            }
          >
            {customRender?.hasColorCode && (
              <div className="qq-calendar-color-code">
                <div className="color-code">
                  <div className="color-code-square" />
                  <h4>{customRender?.colorCodeText}</h4>
                </div>
              </div>
            )}
            <button
              className={'close-calendar ic ic-close'}
              onClick={hideCalendar}
            />
            <DayPickerRangeController
              initialVisibleMonth={() =>
                calendarStartDate && !isMobile ? calendarStartDate : mMinDate
              }
              startDate={calendarStartDate} // required react-dates as bare minimum
              endDate={calendarEndDate} // required react-dates as bare minimum
              focusedInput={focusedInput} // required react-dates as bare minimum
              onFocusChange={handleFocusChange} // required react-dates as bare minimum
              onDatesChange={handleDatesChange} // required react-dates as bare minimum
              isOutsideRange={isOutsideRange}
              numberOfMonths={isMobile ? numberOfMonthsToShow : monthsToDisplay}
              minimumNights={minimumNights}
              orientation={
                isMobile ? VERTICAL_SCROLLABLE : HORIZONTAL_ORIENTATION
              }
              noBorder
              hideKeyboardShortcutsPanel
              withPortal={isMobile}
              onPrevMonthClick={onNavClick}
              onNextMonthClick={onNavClick}
              renderDayContents={
                customRender ? day => customRender?.renderFunc(day) : null
              }
              navPrev={
                !isMobile && prevVisible ? (
                  <i
                    role="presentation"
                    aria-label="icon"
                    className="ic-slick-left-arrow"
                  />
                ) : (
                  <div />
                )
              }
              navNext={
                !isMobile && nextVisible ? (
                  <i
                    role="presentation"
                    aria-label="icon"
                    className="ic-slick-right-arrow"
                  />
                ) : (
                  <div />
                )
              }
              transitionDuration={0}
              renderMonthElement={!isMobile ? renderMonthHeader : null}
            />
            <div
              className={`calendar-bottom-bar row middle-xs ${
                showMaxNightsError || showMinNightsError
                  ? 'start-xs'
                  : 'between-xs'
              }`}
            >
              {calendarStartDate && calendarEndDate && (
                <button className="clear-dates" onClick={clearDates}>
                  Clear Dates
                </button>
              )}

              {calendarStartDate &&
                calendarEndDate &&
                !showMaxNightsError &&
                !showMinNightsError && (
                  <button className="apply-dates" onClick={handleApplyDates}>
                    Apply Dates
                  </button>
                )}
              {showMaxNightsError && (
                <span className="max-stay-error">
                  <i
                    role="presentation"
                    aria-label="icon"
                    className="ic-alert"
                  />{' '}
                  Maximum stay: 21 Nights
                </span>
              )}

              {showMinNightsError && (
                <span className="min-stay-error">
                  <i
                    role="presentation"
                    aria-label="icon"
                    className="ic-alert"
                  />{' '}
                  Minimum stay: {customMinimumNightsError} Nights
                </span>
              )}
            </div>
          </div>
        </CalendarWrapper>
      )}
    </div>
  );
};

export default Calendar;
