import m from 'moment';
import flightsData from '~/js/components/global/qq/flightsData.json';

const { gateways: rawGateways } = flightsData;

const findItemIndex = type =>
  window.dataLayer.findIndex(item => item.event === type);

const isValid = value => {
  return value !== null && value !== undefined && value !== '';
};

const getCurrentGateway = gateway =>
  rawGateways.find(item => item.gateway === gateway);

const formatDates = (checkInDate, checkOutDate, dateFormat = 'MM/DD/YYYY') => {
  let checkIn = m(checkInDate, dateFormat);

  let checkOut = m(checkOutDate, dateFormat);
  const numberOfNights = isNaN(Number(checkOut.diff(checkIn, 'days')))
    ? 0
    : checkOut.diff(checkIn, 'days');

  checkIn =
    m(checkIn).format('MM/DD/YYYY') === 'Invalid date'
      ? ''
      : m(checkIn).format('MM/DD/YYYY');
  checkOut =
    m(checkOut).format('MM/DD/YYYY') === 'Invalid date'
      ? ''
      : m(checkOut).format('MM/DD/YYYY');

  return { checkIn, checkOut, numberOfNights };
};

const getDateFormat = dateString => {
  const defaultFormat = 'MM/DD/YYYY';

  const formats = [
    { regex: /^\d{4}-\d{2}-\d{2}$/, format: 'YYYY-MM-DD' },
    { regex: /^\d{2}-\d{2}-\d{4}$/, format: 'MM-DD-YYYY' },
    { regex: /^\d{2}\/\d{2}\/\d{4}$/, format: 'MM/DD/YYYY' },
    { regex: /^\d{4}\/\d{2}\/\d{2}$/, format: 'YYYY/MM/DD' },
  ];

  for (const { regex, format } of formats) {
    if (regex.test(dateString)) {
      return format;
    }
  }

  return defaultFormat;
};

const updateDataLayer = (currentItem, type) => {
  const itemIndex = findItemIndex(type);

  if (itemIndex !== -1) {
    window.dataLayer[itemIndex] = currentItem;
  } else {
    window.dataLayer.push(currentItem);
  }
};

const handlers = {
  search_site: data => {
    const resorts =
      data?.resorts ??
      window.sandals_app.page?.data?.resorts ??
      window.sandals_app.page?.data?.resort;

    const currentGateway = getCurrentGateway(data.vacationData.gateway);

    const currentFlight = currentGateway
      ? `${currentGateway.city ? currentGateway.city : ''} ${
          currentGateway.airportName ? currentGateway.airportName : ''
        }`
      : '';
    const { checkIn, checkOut, numberOfNights } = formatDates(
      data.vacationData.checkInDate,
      data.vacationData.checkOutDate,
      getDateFormat(data.vacationData.checkInDate)
    );

    let currentResort = Array.isArray(resorts)
      ? resorts.find(
          item =>
            item.code ===
            (isValid(data.vacationData.rstCode)
              ? data.vacationData.rstCode
              : sessionStorage.getItem('generalRstCode'))
        )
      : resorts;

    currentResort = currentResort ?? {
      code: data?.vacationData?.rstCode,
    };

    return {
      flight: currentFlight,
      event: 'search_site',
      brand: 'S',
      checkin: checkIn,
      checkout: checkOut,
      location: currentResort?.country?.name || '',
      resort: currentResort?.code || '',
      number_of_nights: numberOfNights || 0,
    };
  },
  book_now: data => {
    const resorts =
      data?.resorts ??
      window.sandals_app.page?.data?.resorts ??
      window.sandals_app.page?.data?.resort;

    const { checkIn, checkOut, numberOfNights } = formatDates(
      data.vacationData.checkInDate,
      data.vacationData.checkOutDate,
      getDateFormat(data.vacationData.checkInDate)
    );
    const currentResort = Array.isArray(resorts)
      ? resorts.find(
          item =>
            item.code ===
            (isValid(data.vacationData.rstCode)
              ? data.vacationData.rstCode
              : sessionStorage.getItem('generalRstCode'))
        )
      : resorts;

    if (checkIn !== '' && checkOut !== '') {
      return {
        event: 'book_now',
        brand: 'S',
        checkin: checkIn,
        checkout: checkOut,
        location: currentResort?.country?.name || '',
        resort: currentResort?.code || '',
        number_of_nights: numberOfNights || 0,
      };
    }

    return null;
  },
  booknow_room: data => {
    const resorts =
      data?.resorts ??
      window.sandals_app.page?.data?.resorts ??
      window.sandals_app.page?.data?.resort;

    const { checkIn, checkOut, numberOfNights } = formatDates(
      data.vacationData.checkInDate,
      data.vacationData.checkOutDate
    );
    const currentResort = Array.isArray(resorts)
      ? resorts.find(
          item =>
            item.code ===
            (isValid(data.vacationData.rstCode)
              ? data.vacationData.rstCode
              : sessionStorage.getItem('generalRstCode'))
        )
      : resorts;

    return {
      room_category: data.vacationData.categoryCode || '',
      event: 'booknow_room',
      brand: 'S',
      checkin: checkIn,
      checkout: checkOut,
      location: currentResort?.country?.name || '',
      resort: currentResort?.code || '',
      number_of_nights: numberOfNights || 0,
    };
  },
  view_room: data => {
    const resorts = window.sandals_app.page.data?.resorts;
    const currentResort = resorts?.find(
      item => item.code === data.room.rstCode
    );
    const { checkIn, checkOut, numberOfNights } = formatDates(
      data.startDate,
      data.endDate
    );

    return {
      room_category: data.room.roomCategoryCode,
      event: 'view_room',
      brand: 'S',
      checkin: checkIn,
      checkout: checkOut,
      location: currentResort?.country?.name || '',
      resort: currentResort?.code || '',
      number_of_nights: numberOfNights,
    };
  },
  sold_out: data => {
    if (!data.selectedRooms.length) {
      return undefined;
    }
    const resort = window.sandals_app.page.data?.resort;
    const resultRooms = data.selectedRooms.filter(
      roomType => !roomType.availableRooms
    );
    const { checkIn, checkOut, numberOfNights } = formatDates(
      data.startDate,
      data.endDate,
      getDateFormat(data.startDate)
    );

    return {
      number_soldout: resultRooms.length,
      event: 'sold_out',
      brand: 'S',
      checkin: checkIn,
      checkout: checkOut,
      location: resort?.countryName || '',
      resort: resort?.code || '',
      number_of_nights: numberOfNights,
    };
  },
  login: data => {
    const { content } = data;

    return {
      event: 'login',
      brand: 'S',
      member_id: content.account_number,
      level_status: content.member_level,
    };
  },
};

const buildDataLayer = (type, data) => {
  if (!data || !type || !handlers[type]) {
    console.error('Invalid parameters or event type provided.');

    return;
  }

  const currentItem = handlers[type](data);

  if (currentItem) {
    updateDataLayer(currentItem, type);
  }
};

const injectData = (type, data) => {
  if (!data) {
    console.error('Data not found, please provide an object for dataLayer.');

    return;
  }

  buildDataLayer(type, data);
};

const dataLayerRedirect = ({
  url = '',
  resortCode = '',
  type = 'search_site',
  redirectType = '_self',
  resorts = null,
  categoryCode = '',
  sendDataLayer = true,
}) => {
  window.open(url, redirectType);

  let dataLayer = {};

  if (resorts) {
    dataLayer = {
      vacationData: {
        checkInDate: sessionStorage.getItem('startDate'),
        checkOutDate: sessionStorage.getItem('endDate'),
        categoryCode: categoryCode,
      },
      resorts: resorts,
    };
  } else {
    dataLayer = {
      vacationData: {
        checkInDate: sessionStorage.getItem('startDate'),
        checkOutDate: sessionStorage.getItem('endDate'),
        rstCode: resortCode,
        categoryCode: categoryCode,
      },
    };
  }

  if (sendDataLayer) {
    injectData(type, dataLayer);
  }
};

export { injectData, dataLayerRedirect };
