import m from 'moment';

const dateRangeFormat = 'MMM DD, YYYY';
const defaultFormat = 'YYYY-MM-DDTHH:mm:ssZ';
const months1stFormat = 'YYYY-MM-01';
const monthOnlyFormat = 'MMMM, YYYY';
const monthOnlyFormat01 = 'MMM, YYYY';
const obeFormat = 'YYYY-MM-DD';
const qqFormat = 'ddd, MMM DD, YYYY';

const todaysDate = () => {
  return m();
};
const tomorrowsDate = () => {
  return m().add(1, 'days');
};

// By default will convert to OBE format
const momentConverter = (dateValue, dateFormat = obeFormat) => {
  let newDate;

  if (typeof date === 'object') {
    newDate = dateValue;
  } else {
    newDate = m(dateValue, dateFormat, true);
  }

  return newDate;
};

const dateFormatter = (dateValue, oldFormat, newFormat) => {
  const momentDate = momentConverter(dateValue, oldFormat);

  return momentDate.format(newFormat);
};

const isBeforeDate = (date1, date2) => {
  const momentDate1 = momentConverter(date1);
  const momentDate2 = momentConverter(date2);

  return momentDate1.isBefore(momentDate2);
};

const isAfterDate = (date1, date2) => {
  const momentDate1 = momentConverter(date1);
  const momentDate2 = momentConverter(date2);

  return momentDate1.isAfter(momentDate2);
};

const isSameDate = (date1, date2) => {
  const momentDate1 = momentConverter(date1);
  const momentDate2 = momentConverter(date2);

  return momentDate1.isSame(momentDate2);
};

const getFutureDate = (date1, increment, unit) => {
  const momentDate = momentConverter(date1);

  const futureDate = momentDate.add(increment, unit);

  return dateFormatter(futureDate, defaultFormat, obeFormat);
};

const getDatesDiff = (date1, date2, unit) => {
  const momentDate1 = momentConverter(date1);
  const momentDate2 = momentConverter(date2);

  return momentDate1.diff(momentDate2, unit);
};

const formatRate = (rate, decimals = null) => {
  if (typeof rate === 'string') {
    rate = parseFloat(rate);
  }

  return rate?.toFixed(decimals).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export {
  todaysDate,
  tomorrowsDate,
  momentConverter,
  dateFormatter,
  getFutureDate,
  getDatesDiff,
  isBeforeDate,
  isAfterDate,
  isSameDate,
  dateRangeFormat,
  defaultFormat,
  months1stFormat,
  monthOnlyFormat,
  monthOnlyFormat01,
  obeFormat,
  qqFormat,
  formatRate,
};
